.top-arrow-button {
    position: fixed;
    bottom: 14%;
    right: 20px;
    z-index: 1000;
}

.top-arrow-button button {
    background-color: #d9fbdc;
    border: none;
    border-radius: 50%;
    color: black;
    cursor: pointer;
    padding: 10px 15px;
    font-size: 18px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.3);
    transition: opacity 0.3s, transform 0.3s;
}

.top-arrow-button button:hover {
    background-color: #c3e0c5;
}

.top-arrow-button button:focus {
    outline: none;
}

.top-arrow-button button:active {
    transform: scale(0.95);
}
