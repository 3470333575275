/* MultiSelect main container */
.multi-select {
    position: relative;
    width: 100%;
}

.selected-options input {
    border: 0;
    flex: 1; /* Let the input element grow to fill the remaining space */
    padding: 0.8em; /* Adjust padding using em units */
    margin: 0; /* Remove any default margin */
    border-radius: 0.7em;
    background-color:white;
}

/* Adjust the styles for the selected options container to allow input to fill remaining width */
.selected-options {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 0.4em; /* Adjust gap using em units */
    font-size: 1em;
    background-color:  white;
}

.selected-option {
    display: inline-flex; /* Use inline-flex to allow each option to take full width */
    align-items: center;
    padding: 0.4em 0.5em 0.4em 1em; /* Adjust padding using em units */
    margin-right: 0.4em; /* Adjust margin using em units */
    background-color:  #d9fbdc;
    color: black;
    border-radius: 0.8em;
    cursor: pointer;
    min-height: 1em; /* Adjust min-height using em units */
    overflow: hidden;
    text-overflow: ellipsis;
    /* white-space: nowrap; Prevent text from wrapping */
}



  

.selected-option span {
    margin-right: 0.4em; /* Adjust margin using em units */
}

.selected-option button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    font-size: 1.2em; /* Adjust font size using em units */
    color: inherit;
}

/* Dropdown container */
.dropdown {
    position: absolute;
    top: calc(100% + 0.4em);
    left: 0;
    z-index: 250;
    width: 100%;
    max-height: 50vh; /* Adjust max-height using vh units */
    overflow-y: auto;
    background-color: #fff;
    border: 0.1em solid #ced4da; /* Adjust border using em units */
    border-radius: 0.4em; /* Adjust border radius using em units */
    box-shadow: 0 0.2em 0.4em rgba(0, 0, 0, 0.1); /* Adjust box-shadow using em units */
    text-align: left;

}

/* Dropdown option */
.dropdown-option {
    padding: 0.8em;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.dropdown-option:hover {
    background-color: #f0f0f0;
}
