/* App container */
.App {
  margin-bottom: 8vh; /* Set margin-bottom to a percentage of the viewport height */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  font-family: 'Source Sans Pro', sans-serif;
  color: rgb(49, 51, 63);
  /* background-color: #d0d8e1; */
  /* background: linear-gradient(180deg,#f8edd8,#fff 90vh,#fff); */
  /* background: linear-gradient(180deg, #005b9a 0vh, #ffffff 80vh, #ffffff 100%);  */
  max-width: 100vw;

   /* font-family: 'Open Sans', sans-serif; */

  /* font-family: Arial, sans-serif; */
}

.dropdown-container {
  text-align: center;

}
.dropdown-button {
  padding: 8px 20px;
  border-radius: 10px;
  font-size: 1.3em;
  border: 2px solid #24c929;
  background-color: #19e21f;
  color: white;
  font-weight: bold;

}

.loading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #d9fbdc;
  color: #0a580c;
  font-weight: bold;
  padding: 2vh 3vw; /* Adjust padding using viewport units */
  border-radius: 2vw; 
  box-shadow: 0 0 2vh rgba(0, 0, 0, 0.2);
  font-size: 1vw; /* Adjust font size using viewport units */
}
.noInputMessage {
  color: rgb(238, 125, 114);
}

.SearchFormContainer {
  min-height: 95vh;
  display: flex; /* Use flexbox */
  align-items: center; /* Center items vertically */

  /* /* border-radius: 0% 0% 50% 50%;  */

}

/* Search form */
.SearchForm {
  margin: 0px auto 0px auto;
  /* max-width: 80%; */
  width: 100%; 
  max-width: 750px;
  padding: 10vh 6vw;
  /* border: 1px solid rgb(228, 178, 74);; */
  border-radius: 10px;
  /* background-color: #f9f9f9; */
  /* box-shadow: 0 0 20px #757575; */
}

.AppTitle {
  
  font-family: Roboto, sans-serif;
  font-size: 3.5em;
  font-weight: 700;
  margin-top: 0;
  text-align: center;
  margin-bottom: 0vh;
    

}
.AppSubtitle {
  margin-bottom: 4vh;
    font-size: 1.5em;
    text-align: center;
    /* font-weight: 700;
    font-family: Roboto, sans-serif; */

}

.AppTitleTwo{
  margin-top: 0px;
    margin-bottom: 3vh;
    font-size: 2em;
    text-align: left;
  /* font-family: Roboto, sans-serif; */

}


.SearchForm h4 {
  margin:0;
  font-weight: 400;
}

.flexBox{
  display: flex;
  justify-content: space-between;
  margin-bottom: 3vh;
  align-items: center;
}
.refine-search-filters {
  margin:0px;
  padding: 0px;
}

.filter-dropdown-arrow {
  flex-grow:1;
}

.countyMessage {
  /* margin-top: 20px;
  margin-bottom: 10px; */
  font-size: 1.2em;
  text-align: left;
  padding-right: 10px;

}

.addBorder {
  border: 1px solid #005b9a;
  border-radius: 10px;
}

.addPaddingtoCounty {
  padding: 5px;
  /* background-color: #d0d8e1; */
  border-radius: 10px;
  flex-grow: 1; /* This makes the second element take up the remaining space */

}


.help-container {
  margin-top: 20px;
    margin-bottom: 10px;
    position: relative;
    display: flex;
    /* justify-content: space-between; */
    /* align-items: center; */
    border: 1px solid #005b9a;
    border-radius: 5px;
    background-color: #eff4f7;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
}


.help-tooltip {
  cursor: pointer; 
  border: 1.5px solid #005b9a;  
  /* rgb(228, 178, 74); */
  border-radius: 50%;
  color: #005b9a;  
  cursor: pointer;
  font-size: .9em;
  font-weight: 900;
  padding: 2px 8px;
  /* position: absolute;
  right: 0;
  top: 50%; */
}

.tooltip-content {
  text-align: center;
  font-size: 0.8em;
  position: absolute;
  top: calc(100% + 5px); /* Adjust top position to be below the help tooltip */
  right: 0;
  max-width: 60%;
  background-color: #c3e0c5;
  color: #000;
  /* border: 1px solid #e4b24a; */
  border-radius: 0.8em;
  padding: 1em;
  display: none;
  z-index: 1; /* Ensure the tooltip is above other content */
}

.help-tooltip:hover + .tooltip-content {
  display: block;
}



.userInput {
  width: calc(100% - 32px); /* Adjust the width to match the width of the search form */
  min-height: 21vh; /* Set a minimum height */
  font-family: 'Lato', sans-serif;
  
  /* font-family: 'Roboto', sans-serif;  */
  font-size: 1.1em; /* Adjust font size */
  padding: 8px; /* Adjust padding */
  border: 1px solid #ccc; /* Add border */
  border-radius: 4px; /* Add border radius */
  resize: vertical; /* Allow vertical resizing */
  margin-bottom: 10px; /* Add margin */
}

.userInput::placeholder {
  color: darkgrey; 
  /* font-style: italic; */
}




.SearchForm label {
  display: block;
  margin-bottom: 5px;
}

.SearchForm select,
.SearchForm textarea {
  resize: none;
  border: none;
  /* border: 1px solid #005b9a; */
  /* border-radius: 5px; */
  box-sizing: border-box;
  margin-bottom: 1.5vh;
  padding: 10px;
  width: 100%;
  background-color: #eff4f7;
}

.SearchForm textarea:focus {
  outline: none;
}



.SearchFormButton {
  width: 100%;
  padding: 10px;
  color: white;
  background-color: #005b9a;
  border: none;
  border-radius: 0.8em;
  cursor: pointer;
  font-weight: bold;
  font-size: 1.1em;
}

/* Add this CSS to your stylesheet */

.toggle-buttons {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
  align-items: center;
}

.toggle-switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.toggle-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: .4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: .4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #005b9a;
}

input:checked + .slider:before {
  transform: translateX(26px);
}

/* For text inside the slider */
.slider-label {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10px;
  color: white;
}


.SearchResults {
  background-color: white;
  padding: 0 10% 0 10%;
  max-width: 85%;
 
}

.horizontalLine {
  height: 1px; /* Adjust the height of the line */
  width: 100%; /* Set the width to span the entire width of the page */
  background-color: #000; /* Set the color of the line */
  margin-bottom: 7vh;
}

.SearchResult {
  max-width: 100%;
  margin-top: 20px;
}

.SearchResult .title {
  font-size: 1.5em;
  
}

.SearchResult .facility-card {
  border: 1px solid #ccc;
  border-radius: 10px;
  box-shadow: 0 0 5px #757575;
  margin-bottom: 35px;
  display: flex;
  flex-direction: column;
}

.SearchResult .internal-facility-card {
  padding: 9vh 3vw 6vh 5vw
}


.share-button {
  margin: 0vh 1vw 2vh 0vw;
  align-self: flex-end;
  border: 1px solid #ccc;
  background: none;
  padding: 1vh 1vw;
  border-radius: 10px;
}

.share-button a {
  text-decoration: none;
  transition: background-color 0.3s;
}



/* CSS Styling */
.TagsOfInterestBox {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 0.4em;
  font-size: 0.8em;
  margin: 1em 0 0 0;
}

.TagOfInterest {
  /* background-color: #005b9a; */
  color: white;
  padding: 0.4em 0.6em;
  border-radius: 0.8em;
  margin-right: 0.5em; /* Adjust margin as needed */
}

/* 
.TagOfInterest {
  background-color: #6488a7;

  display: inline-flex;
  align-items: center;
  color: white;
  padding: 0.4em 0.6em 0.4em 0.6em;
  border-radius: 0.8em;
  margin-right: 0.1em; 
  min-height: 1em;
  overflow: hidden;
  text-overflow: ellipsis;
} */

.AdditionalTagOfInterest {
  background-color: #7e7e7e;
  
  display: inline-flex; /* Use inline-flex to allow each option to take full width */
  align-items: center;
  color: white;
  padding: 0.4em 0.6em 0.4em 0.6em;
  border-radius: 0.8em;
  margin-right: 0.1em; /* Adjust margin using em units */
  min-height: 1em; /* Adjust min-height using em units */
  overflow: hidden;
  text-overflow: ellipsis;
  /* white-space: nowrap; Prevent text from wrapping */
}


.SearchResult .title {
  font-size: 1.5em;
}

.SearchResult .address {
  font-size: 1em;
}
.SearchResult .contact {
  font-size: 1em;
  margin: 0;
}


.SearchResult .linkBox {
  margin: 1vh 0 2vh;
}
.SearchResult .links {
  font-size: 1em;
  margin: 0 0 0.5vh;
  word-wrap: break-word;
}

.SearchResult .desc {
  font-size: 1em;
  background-color: #005B9A;
  color: white;
  padding: 0.4em 0.6em;
  border-radius: 0.8em;
  margin-right: 0.5em; /* Adjust margin as needed */
}

.SearchResult strong {
  font-weight: bold;
}

.SearchResult a {
  color: #007bff;
  text-decoration: none;
}

.SearchResult a:hover {
  text-decoration: underline;
}


.TagsContainer{
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 0vh 0vw 4vh 0vw;
  position: sticky;
  position: -webkit-sticky; /* For older versions of Safari */
  top: 0;
  background: white;
  padding: 3vh 0vw;
  z-index: 100;
}
.TagsContainer h3 {
  padding-right: 10px;
}

.ResultsBox {
  width: 99%;
  align-self: center;
}
.ResultsBox h3 {
  font-size: 1.5em;
}

.SearchFilterBox {
  flex-direction: column;
  margin-bottom: 10vh;
  display: flex;
}


.bottom-search-message{
  margin-top: 50px;
  font-size: 1em;
  color:darkgrey;
  /* font-style: italic; */
  text-align: center;
}

.footerMessagesContainer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  background-color: #d0d8e1;
  padding: 10px; 
  box-sizing: border-box; 
  color: #005b9a;
  display: flex;
  justify-content: space-between;
  /* z-index: -1; */
  border-top: 1px solid #005b9a;
  align-items: center;
  z-index: 200;
}

.footer-Icons {
  display: flex;
  align-items: center;
}

.footerTitle {
  display: flex; /* Use Flexbox */
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  font-weight: bold;
  font-family: Roboto,sans-serif;
}



.footerTitle p {
  margin: 0px; /* Remove default margin */
  margin-left: 2vw;

  font-size: 1.2em;
  /* font-weight: 700; */
  /* font-family: Roboto, sans-serif; */

}


.footerMessages {
  text-align: right;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 5px 10px;
  z-index: 1;
  gap: 0.1em;
  
}

.footerMessages p {
  margin: 0px;

}


.footerMessages a {
  /* color: rgb(117, 117, 117); */
  color: #005b9a;

  text-decoration: none;
  text-decoration: underline;
}

.footerMessages a:hover {
  text-decoration: underline;
}
.EmergencyWarning{
  font-weight: bold;
  font-size: 1em;
  font-family: 'Roboto';
  padding-bottom: 3px;

}
.DataSources {
  font-size: 0.8em;
}




/* Media query for smaller screens (e.g., phones) */
@media (max-width: 767px) {
  .AppTitle{
    font-size: 2.2em;
  }
  .AppSubtitle {
    font-size: 1.2em;
  }
  .AppTitleTwo{
    font-size: 1.5em
  }
  .footerMessages p {
    margin: 0px;
  }
  .EmergencyWarning{
    font-size: 0.6em;
  }
  .userInput{
    font-size: 1em;
  }
  .TagsContainer {
    position: relative;
  }
  /* .footerMessagesContainer {
    display: block;
    padding: 15px 10px;
  }

  .footerTitle {
    justify-content: left;
    
  }


.footerTitle p {
  padding: 0 0 0 5px;
  font-size: 1em;

}
.footerMessages{
  display: block;
  padding: 0 0 0 0.7em;
  text-align: left;
} */
.DataSources{
  font-size: .55em;

}


}
